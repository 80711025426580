:root {
  --section-bg-color: #f7fafc;
  --link-color: #718196;
  --link-hover-color: #38578a;
  --link-hover-black: #000;
}

#top-container {
  min-height: 100vh;
  position: relative;
  background-color: var(--section-bg-color);
  .top-banner-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
    text-align: left;
    .top-banner {
      max-width: calc(700px + 2rem);
      margin: 0 auto;
      position: relative;
      padding: 1rem;
      .sub-heading {
        font-size: 24px;
        font-weight: 300;
        padding-bottom: 0.5em;
      }
      .nav-tabs {
        list-style-type: none;
        padding: 0;
        li {
          font-size: 18px;
          padding-right: 1em;
          font-weight: 600;
          a, span,
          a:visited, span:visited {
            cursor: pointer;
            text-decoration: none;
            color: var(--link-color);
            transition: 0.2s;
          }
          a:hover, span:hover {
            color: var(--link-hover-black);
            transition: 0.5s;
          }
        }
      }
    }
  }
  .down-icon-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    bottom: 3em;
    .down-icon{
      height:40px;
      width: 40px;
      cursor: pointer;
      animation-name: down-icon;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      &:hover {
        animation-play-state: paused
      }
    }
    @keyframes down-icon {
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, 15px); }
      100%   { transform: translate(0, -0px); }    
    }
  }
}
.wrapper-spacing {
  max-width: calc(700px + 2rem);
  margin: 0 auto;
  padding: 4rem 1rem;
}
.main-container {
  .about-me-wrapper {
    position: relative;
    background-color: var(--section-bg-color);
  }
}

footer {
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: var(--section-bg-color);
  a,
  a:visited {
    text-decoration: none;
    color: var(--link-color);
    transition: 0.2s;
    font-weight: 600;
  }
  a:hover {
    color: var(--link-hover-color);
    text-decoration: underline;
    transition: 0.5s;
  }
}

@media only screen and (max-width: 766px) {
  #top-container {
    .top-banner-wrapper{
      top: 15%;
      .top-banner {
        .nav-tabs {
          text-align: center;
          margin-top: 3em;
          li {
            display: inline-block;
            font-size: 16px;
            padding-right: 1.5em;
            margin-bottom: 1em;
          }
        }
      }
    }
    .down-icon-wrapper{
      bottom: 15%;
    }
  }
}

@media only screen and (min-width: 767px) {
  .top-banner {
    .nav-tabs li {
      display: inline-block;
    }
  }
}
